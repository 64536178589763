import React from 'react';
import { GenericPage, fetchPageData } from 'components/Page';

export const InvestorsHome = (props) => {
  return <GenericPage {...props} hideNavigation />;
};

export const getServerSideProps = async (ctx) =>
  fetchPageData({ slug: 'home', ctx });

export default InvestorsHome;
